// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-js": () => import("../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-pricing-js": () => import("../src/pages/en/pricing.js" /* webpackChunkName: "component---src-pages-en-pricing-js" */),
  "component---src-pages-en-purpose-js": () => import("../src/pages/en/purpose.js" /* webpackChunkName: "component---src-pages-en-purpose-js" */),
  "component---src-pages-en-suggestions-js": () => import("../src/pages/en/suggestions.js" /* webpackChunkName: "component---src-pages-en-suggestions-js" */),
  "component---src-pages-en-tutorials-js": () => import("../src/pages/en/tutorials.js" /* webpackChunkName: "component---src-pages-en-tutorials-js" */),
  "component---src-pages-es-js": () => import("../src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-es-pricing-js": () => import("../src/pages/es/pricing.js" /* webpackChunkName: "component---src-pages-es-pricing-js" */),
  "component---src-pages-es-purpose-js": () => import("../src/pages/es/purpose.js" /* webpackChunkName: "component---src-pages-es-purpose-js" */),
  "component---src-pages-es-suggestions-js": () => import("../src/pages/es/suggestions.js" /* webpackChunkName: "component---src-pages-es-suggestions-js" */),
  "component---src-pages-es-tutorials-js": () => import("../src/pages/es/tutorials.js" /* webpackChunkName: "component---src-pages-es-tutorials-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-update-password-js": () => import("../src/pages/update-password.js" /* webpackChunkName: "component---src-pages-update-password-js" */)
}

